
.form-background {
    background-color: white;
}
.banner {
    margin: 0;
    position: absolute;
    /* left: 30%; */
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
h1 {
    font-weight: 600 !important;
    font-size: 2.5vw !important;
    color: #00447c;
}
h3 {
    font-size: 2.0vw !important;
}
.card-style {
    padding-top: 100px;
    width: 100%;
    height: 100%;
    margin-left: -30px;
    align-items: center;
    border: none !important;
}
.card-body-style {
    text-align: center !important;
    align-items: center !important;
    width: 80rem;
    margin-top: -95px !important;
    /* margin-left: -0px !important; */
    padding-top: 0px !important;
    border: none !important
}
.form-title {
    font-size: 3rem;
    font-weight: bold;
    color: #00447c;
    text-align: center !important;
}
.Toastify__toast-body {
    font-weight: bold;
    color: #00447c;
}

@media (max-width: 1580px) {
    .banner {
        /* margin-left: 100px; */
        /* left: 30% */
    }

}