.sidebar-menu-container {
    height: 100%; /* Full-height: remove this if you want "auto" height */
    width: 290px;
    position: fixed; /* Fixed Sidebar (stay in place on scroll) */
    z-index: 1; /* Stay on top */
    top: 0; /* Stay at the top */
    left: 0;
    text-align: center;
    color: white;
    background-color: #00447c ;
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 140px;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 20px
}
a {
    color: white;
    text-decoration: none;
}
.menu-button {
    color: white;
    border: none !important;
    border-color: #00447c !important;
    margin-left: 0px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    background-color: #00447c !important;
    max-width: 280px !important;
    box-shadow: none !important;
}
.menu-button:hover {
    /* font-size: 105% !important; */
    font-weight: 600;
}
.menu-button:focus {
    outline: 0 !important;
    outline-color:  #00447c !important;
}
.menu-button:active {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}
#user-menu {
    right: 3em;
    width: 100%;
    color: white;
    text-align: center;
    margin-right: 0px;
    margin-left: 0px;
    margin-top: 0px;
}
@media (max-width: 1580px) {
    .sidebar-menu-container {
        width: 200px;
        padding-left: 10px;
        padding-right: 10px;
        margin-right: 10px;
        font-size: 90% !important;
    }
    .menu-button {
        padding-top: 4px !important;
        padding-bottom: 4px !important;
        max-width: 280px !important;
        box-shadow: none !important;
        font-size: 90% !important;

    }
    .menu-button:hover {
        font-size: 92% !important;
    }
}
@media (max-width: 1480px) {
    .sidebar-menu-container {
        width: 150px;
        padding-left: 0px;
        padding-right: 0px;
        margin-right: 0px;
        font-size: 90% !important;
    }
    .menu-button {
        padding-top: 4px !important;
        padding-bottom: 4px !important;
        max-width: 280px !important;
        box-shadow: none !important;
        font-size: 90% !important;
    }
    .menu-button:hover {
        font-size: 91% !important;
    }
}
@media (max-width: 1300px) {
    .sidebar-menu-container {
        width: 120px;
        padding-left: 0px;
        padding-right: 0px;
        margin-right: 0px;
        font-size: 80% !important;
    }
    .menu-button {
        padding-top: 4px !important;
        padding-bottom: 4px !important;
        max-width: 280px !important;
        box-shadow: none !important;
        font-size: 80% !important;
    }
    .menu-button:hover {
        font-size: 80.05% !important;
    }
}