.form-box {
    align-items: center;
    /* text-align: center; */
    margin-left: 0rem;
    margin-right: 0rem;
}
.orange-text {
  color:  orange;
  font-weight: bold;
  font-size: 2.0rem;
}
.label-format {
    font-size: 1.5rem;
}
.input-format {
    width: 90%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: 1.5px 2px 2.5px 0px #00447c;
    white-space: pre-wrap;
}

#content input[disabled] {
    background-color: #ccc;
    padding: 2px;
    margin: 0 0 0 0;
    background-image: none;
}

.button-box {
    height: 150px;
    padding-bottom: 20px;
}

.blue-button {
    white-space: nowrap;
    display: inline-block !important;
    margin-top: 50px !important;
    margin-bottom: 10px !important;
    margin-left: 0px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    -webkit-box-pack: end !important;
    -webkit-justify-content: flex-end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
    -webkit-box-align: stretch !important;
    -webkit-align-items: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
    border-radius: 8px !important;
    background-color: #00447c !important;
    font-weight: 500 !important;
    text-align: center !important;
    max-width: 400px !important;
    min-width: 100px !important;
  }
  .blue-button:hover {
    background-color: #fed000 !important;
    color: #000;
    font-weight: 600;
  }
  .yellow-button {
    align-self: center;
    position: relative;
    display: inline-block !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    /* margin-left: 16rem !important; */
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    -webkit-box-pack: end !important;
    -webkit-justify-content: flex-end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
    -webkit-box-align: stretch !important;
    -webkit-align-items: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
    border-radius: 8px !important;
    background-color: #fed000 !important;
    color: #00447c !important;;
    font-weight: 500 !important;
    text-align: center !important;
    max-width: 200px !important;
    min-width: 100px !important;
  }
  .yellow-button:hover {
    background-color: #00447c !important;
    color: white !important;
    font-weight: 600;
  }
  /* .yellow-button:focus {
      background-color: #00603a;
      color: white;
      font-weight: 600;
  } */

  .display-icon {
    width: 40px;
    height: 40px;
}